// import { message, notification, Button } from "antd";
import { message } from "antd";
import axios from "axios";
import { clearCookie } from "utils";
// import { useHistory } from "react-router-dom";
// import { cancelRequestLogin } from "./Auth/authAPI";
// import { logout } from "./Auth/authAPI";

// const close = (actionKey) => {
//     if (actionKey === 'cancel') {
//         cancelRequestLogin().then(req => {
//             message.success("Hủy lệnh yêu cầu đăng nhập từ thiết bị khác thành công")
//         }).catch(error => {
//             message.error(error?.response?.data?.message)
//         })
//     }
//     else {
//         logout().then(req => {
//             localStorage.removeItem("auth")
//             localStorage.removeItem("token")
//             window.location.replace("/")
//         })
//     }
//     notification.close('noti')
// }

// const openNotification = (title) => {
//     const key = `noti`;
//     const btn = (
//         <>
//             <Button type="danger" className="mr-2" onClick={() => close('cancel')}>
//                 Hủy yêu cầu
//             </Button>
//             <Button type="primary" onClick={() => close('logout')}>
//                 Đăng xuất
//             </Button>
//         </>
//     );
//     notification.open({
//         message: title,
//         description: 'Hủy lệnh yêu cầu đăng nhập để tiếp tục công việc hoặc đăng xuất trên thiết bị hiện tại để đăng nhập từ thiết bị khác.',
//         btn,
//         key,
//         duration: 0,
//         onClose: close,
//         closeIcon: false
//     });
// };

const version = "v1/";
const instance = axios.create({
    baseURL: process.env.REACT_APP_API + version + "admin-panel/",
});

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

let reportSiteAuth = "";
for (let i = 1; i < 10; i++) {
    let c = getCookie("myvnclub-report-site-auth" + i);
    if (c.length > 0) {
        reportSiteAuth += c;
    }
}

// Mặc định thì em sẽ check xem có auth ở trên url không.
// Sau đó em kiểm tra xem thoạt nhìn, auth nó có hợp lệ không > 4000 ký tự
// Nếu có thì em lưu nó vào local
if (reportSiteAuth && reportSiteAuth.length > 500) {
    window.localStorage.setItem("auth", reportSiteAuth);
}

// instance.interceptors.request.use(function (config) {
//     return config;
// }, function (error) {
//     return Promise.reject(error)
// })

var displayAlert = true;

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
        ) {
            if (displayAlert) {
                if (error?.response?.status === 401)
                    message.error("Phiên đăng nhập đã hết hạn");
                if (error?.response?.status === 403)
                    message.error("Bạn không có quyền truy cập tính năng này");
                displayAlert = false;
            }
            // clear cookies
            for (let i = 1; i < 10; i++) {
              clearCookie("myvnclub-report-site-auth" + i);
            }
            localStorage.removeItem("auth");
            localStorage.removeItem("token");
            setTimeout(() => window.location.replace("/"), 2000);
        }
        return Promise.reject(error);
    }
);

export default instance;
