import _ from "lodash";
import moment from "moment";
// import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const renderURL = (url, params, formatter) => {
  let paramsValue = _.compact(
    _.map(params, (p, key) => {
      if (p) {
        if (typeof p === "object") {
          if (p.id) return `${key}=${p.id}`;
          else if (_.isArray(p)) {
            return `${key}=[${p}]`;
          } else {
            let formatDate = moment(p).format(
              formatter ? formatter : "DD-MM-YYYY"
            );
            return `${key}=${formatDate}`;
          }
        } else {
          return `${key}=${p}`;
        }
      }
    })
  );
  return _.map(paramsValue, (pv, index) => (!index ? pv : "&" + pv)).join("");
};

async function creatPdf({ doc, elements }) {
  let top = 20;
  const padding = 10;

  // for (let i = 0; i < elements.length; i++) {
  // const el = elements.item(i);
  try {
    const imgData = await html2canvas(elements); //await htmlToImage.toCanvas(elements);

    let elHeight = elements.offsetHeight;
    let elWidth = elements.offsetWidth;

    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding;
      elWidth = elWidth * ratio - padding;
    }

    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = 20;
    }

    if (imgData)
      doc.addImage(
        imgData.toDataURL("image/png"),
        "PNG",
        padding,
        top,
        elWidth,
        elHeight,
        `image${1}`
      );
    top += elHeight;
  } catch (err) {
    console.log("err", err);
  }
  // }
}

export const downloadPDF = async (id) => {
  const doc = new jsPDF("p", "px"); // (1)
  const elements = document.getElementById(id); // (2)

  await creatPdf({ doc, elements }); // (3-5)
  doc.save(`${id}.pdf`); // (6)
};


export function clearCookie(cookieName) {
  const domain = process.env.REACT_APP_COOKIE_DOMAIN || '';
  const path = "/";
  const secure = window.location.protocol === 'https:' ? 'Secure;' : '';
  
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain}; ${secure}`;
}